import React from 'react'
import Presentation from './components/Presentation'



function App() {
    return (
        <Presentation />
    )
}

export default App