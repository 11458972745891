import React, { useEffect, useState } from 'react'
import video1 from '../assets/banner1.mp4'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import swal from 'sweetalert'
import love_story from '../assets/music/love story.mp3'
import valentine from '../assets/music/valentine.mp3'
import yaman from '../assets/music/Tanging Yaman.mp3'
import AOS from 'aos'
import { motion } from "framer-motion";
import { Image } from 'primereact/image'
import img1 from '../assets/images/1.png'
import img2 from '../assets/images/2.png'
import img5 from '../assets/images/3.png'
import img6 from '../assets/images/4.png'
import img7 from '../assets/images/5.png'
import img8 from '../assets/images/6.png'
import img3 from '../assets/images/hermother.png'
import img4 from '../assets/images/parents.png'
import { TypeAnimation } from 'react-type-animation';

function Presentation() {

    useEffect(() => {
        AOS.init();
    }, [])

    const [authenticate, setAuthenticate] = useState(0);
    const [pin, setPin] = useState([]);
    const Submit = (e) => {
        e.preventDefault();

        // sherilyn
        if (pin === "111820") {
            setAuthenticate(1)
        }
        // babyluz
        else if (pin === "693189") {
            setAuthenticate(2)

        }
        // ate kym
        else if (pin === "600813") {
            setAuthenticate(3)
        }
        // mama
        else if (pin === "404981") {
            setAuthenticate(4)
        }
        else {
            swal("Warning", "Incorrect Pin", 'warning')
            document.getElementById('form_reset').reset();
        }
    }

    return (
        <>

            {
                authenticate === 1 ?
                    <>
                        <motion.div
                            initial={{ opacity: 0, scale: 0.5 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{
                                duration: 0.8,
                                delay: 0.4,
                                ease: [0, 0.71, 0.2, 1.01]
                            }}
                        >
                            <video data-aos="fade-up" autoPlay loop muted playsInline>
                                <source src={video1}></source>
                            </video>

                        </motion.div>

                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 mb-3 col-sm-12" data-aos="fade-left"
                                    data-aos-easing="linear"
                                    data-aos-duration="1500">
                                    <Image src={img5} width='100%' preview />
                                </div>
                                <div className="col-lg-6 mb-3 col-sm-12" data-aos="fade-up"
                                    data-aos-easing="linear"
                                    data-aos-duration="1500">
                                    <Image src={img1} width='100%' preview />
                                </div>
                                <div className="col-lg-6 mb-3 col-sm-12" data-aos="fade-right"
                                    data-aos-easing="linear"
                                    data-aos-duration="1500">
                                    <Image src={img2} width='100%' preview />
                                </div>
                                <div className="col-lg-6 mb-3 col-sm-12" data-aos="fade-left"
                                    data-aos-easing="linear"
                                    data-aos-duration="1500">
                                    <Image src={img6} width='100%' preview />
                                </div>
                                <div className="col-lg-6 mb-3 col-sm-12" data-aos="fade-up"
                                    data-aos-easing="linear"
                                    data-aos-duration="1500">
                                    <Image src={img7} width='100%' preview />
                                </div>
                                <div className="col-lg-6 mb-3 col-sm-12" data-aos="fade-down"
                                    data-aos-easing="linear"
                                    data-aos-duration="1500">
                                    <Image src={img8} width='100%' preview />
                                </div>
                                <div className="mt-5">
                                    <div className="container">
                                        <TypeAnimation
                                            sequence={[
                                                `Dear Sherilyn,
                                                
                                            \n Mahal, Happy Valentines day 😍, Mahal, Hindi mo lang alam kung gaano mo ako napasaya, nong dumating ka sa buhay ko di ko talaga ma explain kung gaano ako kasaya. Mahal sa dinami daming babae sa mundo ikaw yung iba para sakin mahal. Mahal Hinding hindi ako magbabago at patuloy kong ipapadama yung pagmamahal ko sayo mahal. Lahat ay gagawin, lahat ay titiisin, Wag na wag ka lang mawawala sa piling ko mahal kasi ikaw lang yung babae nandito sa puso ko at wala ng iba. Wala ng akong hinihiling kasi nanjan kana para sakin mahal. Once Happy Valentines Day Mahal,

                                            
                                            \nYour Special Person,

                                            Georgie Recabo 💓💖`
                                            ]}
                                            wrapper="p"
                                            speed={50}
                                            style={{ whiteSpace: 'pre-line', fontSize: '20px', display: 'inline-block', color: 'black' }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <audio autoPlay loop>
                            <source src={love_story} type="audio/mpeg" />
                        </audio>
                    </>
                    : authenticate === 2 ? <>
                        <Image src={img3} width='100%' />

                        <audio autoPlay loop>
                            <source src={valentine} type="audio/mpeg" />
                        </audio>
                    </>
                        :
                        authenticate === 3 ? "Kym" :
                            authenticate === 4 ? <>
                                <Image src={img4} width='100%' />

                                <audio autoPlay loop>
                                    <source src={yaman} type="audio/mpeg" />
                                </audio>
                            </> :

                                <React.Fragment>
                                    <div className="banner_img">
                                        <div className="container">
                                            <div className="d-flex justify-content-center align-items-center vh-100">
                                                <form onSubmit={Submit} id='form_reset'>
                                                    <div className="container">
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <label htmlFor="" className="form-label text-center">
                                                                    Enter 6 Pin Number
                                                                </label>
                                                                <InputText keyfilter={'pint'} placeholder='' className='p-inputtext-sm w-100' onChange={(e) => setPin(e.target.value)} maxLength={6} type='password' />
                                                                <div className="mt-3">
                                                                    <Button className='p-button-sm p-button-danger w-100' label='Enter' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
            }

        </>
    )
}

export default Presentation